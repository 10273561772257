.text-center {
  text-align: center;
}

.flex {
  display: flex;
}

.text-capitalize {
  text-transform: capitalize;
}

.mobile-container {
  max-width: 500px;
  margin: auto;
}

.font-be-normal {
  font-family: "Be Vietnam", sans-serif;
  font-weight: 400;
}

.font-be-medium {
  font-family: "Be Vietnam", sans-serif;
  font-weight: 500;
}

.font-be-semibold {
  font-family: "Be Vietnam", sans-serif;
  font-weight: 600;
}

.font-be-bold {
  font-family: "Be Vietnam", sans-serif;
  font-weight: 700;
}

.font-osw-normal {
  font-family: "Oswald", sans-serif;
  font-weight: 400;
}

.font-osw-medium {
  font-family: "Oswald", sans-serif;
  font-weight: 500;
}

.font-osw-semibold {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
}

.font-osw-bold {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
}

.text-xl {
  font-size: 20px;
  line-height: 28px;
}

.text-2xl {
  font-size: 24px;
  line-height: 32px;
}

.mt-20 {
  margin-top: 20px;
}
