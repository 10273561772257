.growth-container {
  background-color: #eff0f2;
  .growth-header {
    background-image: url(../assets/images/img_growth_header.png);
    width: 100%;
    background-repeat: round;
    height: 215px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px;
    .countdown-container {
      margin-left: 10px;
      display: flex;
      .countdown-item {
        background: linear-gradient(180deg, #484242 0%, #000000 100%);
        border-radius: 3px;
        width: 24px;
        height: 24px;
        margin-right: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-family: "Be Vietnam", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #fff;
        }
      }
    }
    .gold-member-text {
      display: flex;
      align-items: center;
      margin-top: 8px;
      padding-left: 30px;
      .gold-member-icon {
        margin-left: 6px;
      }
    }
    .description-text {
      margin-top: 4px;
      font-size: 12px;
    }
  }
  .growth-body {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    margin-top: -10px;
    background-color: #fff;
    &-content {
      padding: 20px;
      padding-bottom: 0;
      &-span1,
      &-span2 {
        font-size: 14px;
      }
      &-span2 {
        margin-top: 5px;
      }
    }
    &-form {
      margin: 15px 10px 20px 10px;
      padding-bottom: 20px;
      &-item {
        margin-bottom: 5px;
        width: 100%;
        .form-content {
          background: #fafafa;
          border-radius: 10px;
          padding: 12px;
          flex: 1;
          .ant-form-item {
            margin-bottom: 0;
          }
          .ant-input-affix-wrapper-focused {
            box-shadow: none;
            border-bottom: 1px solid #fabd05 !important;
          }
          .ant-form-item-explain-error {
            font-size: 12px;
          }
          .form-custom-input {
            border: none;
            background: transparent !important;
            padding-left: 0;
            border-bottom: 1px solid #d1d1d1;
            border-radius: 0;
            .frefix-custom {
              font-family: "Be Vietnam", sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 26px;
              color: #767676;
              width: 36px;
              .required {
                color: #e54d3e;
              }
            }
            input[type="text"].ant-input::-webkit-input-placeholder {
              color: #a3a3a3;
              font-weight: 500;
            }
            .ant-input {
              background: transparent;
              padding-left: 40px;
              font-family: "Be Vietnam", sans-serif;
              font-style: normal;
              font-size: 14px;
              line-height: 26px;
              font-weight: 600;
            }
            .ant-input-disabled {
              color: #000000;
            }
          }
        }
        .form-dash {
          height: 80%;
          margin: 10px 0 0 0;
        }
        .form-action {
          background: #fafafa;
          border-radius: 10px;
          padding: 7px;
          display: flex;
          align-items: center;
          justify-content: center;
          .button-enable {
            background: #ffcc33;
            border-radius: 5px;
            border: none;
            padding: 0 15px;
            height: 35px;
            cursor: pointer;
            span {
              font-family: "Oswald", sans-serif;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              margin: 5px 0;
              color: #222222;
            }
          }
          .button-disable {
            background: #e8e8e8;
            border-radius: 5px;
            border: none;
            padding: 0 15px;
            height: 35px;
            span {
              font-family: "Oswald", sans-serif;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              margin: 5px 0;
              color: #767676;
            }
          }
        }
      }
    }
  }
  .growth-questions {
    background-color: #fff;
    margin-top: 10px;
    padding: 10px 20px;
    padding-bottom: 40px;
    &-header {
      text-align: center;
      margin-bottom: 7px;
      font-family: "Be Vietnam", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    }
    &-item {
      position: relative;
      border: none;
      background-color: #fff;
      margin-bottom: 8px;
      .ant-collapse-item {
        position: relative;
        border: 1px solid #d1d1d1;
        border-radius: 5px;
      }
      .ant-collapse-item-active {
        position: relative;
        border: 1px solid #5f5f5f;
        .ant-collapse-header {
          .ant-collapse-header-text {
            font-family: "Be Vietnam", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #3d3d3d;
          }
        }
      }
      .ant-collapse-header {
        padding: 10px;
        .ant-collapse-expand-icon {
          .question-icon-custom {
            font-size: 18px;
            position: absolute;
            top: 20px;
          }
        }
        .ant-collapse-header-text {
          font-family: "Be Vietnam", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #767676;
        }
      }
      .ant-collapse-content {
        border-top: none;
        border-radius: 0 0 5px 5px;
        .ant-collapse-content-box {
          padding: 10px;
          padding-top: 0;
          .question-content {
            font-family: "Be Vietnam", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

.growth-popup-message {
  top: 200px;
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-body {
      padding: 50px 20px;
      div {
        font-family: "Be Vietnam", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .ant-modal-footer {
      padding: 0;
      border-top: none;
    }
  }
}
